

















import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import HeroDefault from '@/components/hero/Default.vue'
import UiFlexible from '@/components/ui/Flexible.vue'

export default defineComponent({
  name: 'ExperienceSingle',
  components: {
    HeroDefault,
    UiFlexible,
  },
  setup() {
    const { content } = useGetters(['content'])
    let promoLabel: string | null = null

    // Get promo label to add on hero
    // Filter flexible components to get cpts that can add anchors
    const anchorComponents = content.value.flexibleContent.filter(
      component =>
        component.component === 'highlightDuo' ||
        component.component === 'ctaPromo'
    )

    // Find the (first) one with an anchor
    const anchorComponent = anchorComponents.find(cta => cta.content.anchor)

    if (anchorComponent) {
      promoLabel = anchorComponent.content.anchor.label
    }

    return {
      content,
      promoLabel,
    }
  },
})
